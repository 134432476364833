import React, {useEffect, useState} from "react";
import {Steps as AntDSteps} from "antd";
import PropTypes from "prop-types";
import styled from 'styled-components'
import {Flex} from "../../flex";
import {Spacing} from "../spacing";
import {Icon} from "../icon";

export const Steps = ({current, config, canEdit}) => {
    const adjustedCurrent = current + 1
    const [openSections, setOpenSections] = useState([adjustedCurrent])
    const filteredSectionEnabled = !canEdit
        ? config.filter((item) => item.data.sectionEnabled === true)
        : config;

    const handleClick = (id, show) => {
        if (openSections.includes(id) && id !== adjustedCurrent) {
            setOpenSections(openSections.filter((item) => item !== id))
        } else {
            if (show && !openSections.includes(id)) {
                setOpenSections([...openSections, id])
            }
        }
    }

    useEffect(() => {
        setOpenSections([...openSections, adjustedCurrent])
    }, [current])

    return (
        <StyledSteps current={current} direction="vertical">
            {filteredSectionEnabled?.map((item) => (
                <AntDSteps.Step
                    title={
                        <StyledHeader onClick={() => handleClick(item.step, item.show)} show={item.show}>
                            <Flex stretch justify={Flex.POSITION.SPC_BETWEEN}>
                                {item.title}
                                {!canEdit && item.step !== adjustedCurrent && (
                                    <>
                                        <Spacing size={Spacing.SIZES.SIZE_16} type={Spacing.TYPES.HORIZONTAL}/>
                                        {openSections.includes(item.step) ?
                                            <Icon name={Icon.ICONS.ARR_DOWN} size={Icon.SIZES.SMALL}/> :
                                            <Icon name={Icon.ICONS.ARR_RIGHT} size={Icon.SIZES.SMALL}/>
                                        }
                                    </>

                                )
                                }
                            </Flex>
                        </StyledHeader>
                    }
                    key={item.step}
                    description={openSections.includes(item.step) ? item.content : <Spacing size={Spacing.SIZES.SIZE_10}/>}
                />
            ))}
        </StyledSteps>
    );
};

Steps.propTypes = {
    current: PropTypes.number.isRequired,
    config: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.node,
            content: PropTypes.node,
        })
    ).isRequired,
};

const StyledHeader = styled.div`
    cursor:${({show}) => show ? 'pointer' : 'default'}
`

const StyledSteps = styled(AntDSteps)`

    .ant-steps-item-title {
        width: 100%;
        margin-bottom: 16px;
    }
`
