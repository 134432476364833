import {Col, Tabs as AntTabs} from "antd";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import {Spacing} from "../index";

export const Tabs = ({onEdit, onChange, value, config, canEdit}) => {
    return (
        <TabsContainer>
            <Col lg={0}>
                <Spacing size={Spacing.SIZES.SIZE_8} type={Spacing.TYPES.HORIZONTAL}/>
            </Col>
            <AntTabs
                style={{
                    margin: 0,
                    padding: 0,
                    color: "#B3D2FF",
                }}
                size="large"
                onChange={(value) => onChange(value)}
                activeKey={value}
                items={config}
                type={!canEdit && "editable-card"}
                hideAdd={!!canEdit}
                onEdit={onEdit}
            />
        </TabsContainer>
    );
};

Tabs.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    config: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.node,
            content: PropTypes.node,
            key: PropTypes.string,
            closable: PropTypes.bool,
        })
    ).isRequired,
};

const TabsContainer = styled.div`
  .ant-tabs-nav {
    margin-bottom: 0;
    margin-top: 16px;
  }
  .ant-tabs-ink-bar {
    outline-width: 15px;
  }
`;
