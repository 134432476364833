import {Card, Row, Col} from "antd";
import styled from "styled-components";
import {Flex} from "../../flex";
import {
    Input,
    Icon,
    Checkbox,
    DatePicker,
    AlertInput,
    Select,
    FileInput,
    Spacing, Button,
} from "../index";
import {statusTypes} from "../../constants";
import {StatusTag} from "../StatusTag";

export const Flight = ({
                           flightItem,
                           handleChange,
                           handleFlightDelete,
                           canEdit,
                           form,
                           formType,
                           flightIndex,
                           emailOrder
                       }) => {
    const isDelayed = form.status === "delayed";
    const isLate = form.status === "late";
    return (
        <div>
            <Spacing size={Spacing.SIZES.SIZE_16} type={Spacing.TYPES.VERTICAL}/>
            <Card>
                <Row justify='space-between' align='bottom'>
                    <Col span={14}>
                        <Row align='bottom'>
                            <Col span={24} md={17}>
                                <Input
                                    text=" Flight"
                                    suffixIcon={<Icon name={Icon.ICONS.VECTOR}/>}
                                    onChange={(value) => handleChange("flightNumber", value)}
                                    value={flightItem.flightNumber}
                                    disabled={!canEdit}
                                />
                            </Col>
                            <Col offset={1} style={{display: 'flex', alignItems: 'center'}}>
                                <Checkbox
                                    label="In transit"
                                    onChange={(value) => handleChange("inTransit", value)}
                                    value={flightItem.inTransit}
                                    disabled={!canEdit}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col span={10}>
                        <Row align='bottom' style={{justifyContent: 'end'}}>
                            <Col span={10}>
                                {canEdit && (
                                    <Select
                                        text="Status"
                                        onChange={(value) => {
                                            handleChange("status", value);
                                            if (value === 'done' || value === 'delayed') {
                                                emailOrder()
                                            }
                                        }}
                                        options={statusTypes}
                                        value={form.status}
                                        disabled={!canEdit}
                                    />
                                )}
                            </Col>
                            {!canEdit && (
                                <StatusTag status={form.status}/>
                            )}
                            {canEdit && (
                                <Col offset={1}>
                                    <Button
                                        danger
                                        type={Button.TYPES.primary}
                                        onClick={handleFlightDelete}
                                        text='Delete'
                                    />
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>
                <Spacing size={Spacing.SIZES.SIZE_20}/>
                <Row>
                    <Col span={24} md={10}>
                        <Input
                            text="Departure Airport"
                            value={flightItem.departureAirport}
                            onChange={(value) => handleChange("departureAirport", value)}
                            disabled={!canEdit}
                        />
                    </Col>
                    <Col span={24} md={{span: 10, offset: 1}}>
                        <Input
                            text="Arrival Airport"
                            value={flightItem.arrivalAirport}
                            onChange={(value) => handleChange("arrivalAirport", value)}
                            disabled={!canEdit}
                        />
                    </Col>
                </Row>
                <Spacing size={Spacing.SIZES.SIZE_14}/>
                {formType === 'airfreight' && flightIndex === 0 && (
                    <>
                        <Checkbox
                            label="Goods tendered to airline's handling agent"
                            value={form.goodsTendered}
                            onChange={(value) => {
                                handleChange("goodsTendered", value);
                                if (value) {
                                    emailOrder()
                                }
                            }}
                            disabled={!canEdit}
                        />
                        <Spacing
                            size={Spacing.SIZES.SIZE_12}
                            type={Spacing.TYPES.HORIZONTAL}
                        />
                    </>
                )}
                <Spacing size={Spacing.SIZES.SIZE_18} type={Spacing.TYPES.VERTICAL}/>
                {formType === 'obc' && flightIndex === 0 && (
                    <Row gutter={[0, 20]}>
                        <Col span={24} md={12}>
                            <Checkbox
                                label="Export customs done"
                                value={form.exportCustoms}
                                onChange={(value) => handleChange("exportCustoms", value)}
                                disabled={!canEdit}
                            />
                        </Col>
                        <Col span={24} md={12}>
                            <Checkbox
                                label="Goods checked-in as a luggage"
                                value={form.goodsCheckedIn}
                                onChange={(value) =>
                                    handleChange("goodsCheckedIn", value)
                                }
                                disabled={!canEdit}
                            />
                        </Col>
                    </Row>
                )}
                {formType === 'obc' && (
                    <>
                        <Spacing size={Spacing.SIZES.SIZE_14}/>
                        <Col span={24}>
                            <Checkbox
                                label="Goods confirmed on board by the gate staff member"
                                value={form.goodsConfirmedOnboard}
                                onChange={(value) =>
                                    handleChange("goodsConfirmedOnboard", value)
                                }
                                disabled={!canEdit}
                            />
                        </Col>
                    </>
                )}
                <Spacing size={Spacing.SIZES.SIZE_14} type={Spacing.TYPES.VERTICAL}/>
                <DatePicker
                    text={"ETD:"}
                    value={flightItem.estimatedTime}
                    showTime={flightItem.estimatedTimeTime}
                    onChange={(value) => handleChange("estimatedTime", value)}
                    toggleTime={(value) => handleChange("estimatedTimeTime", value)}
                    disabled={!canEdit}
                />
                <Spacing size={Spacing.SIZES.SIZE_20} type={Spacing.TYPES.VERTICAL}/>
                <DatePicker
                    text={"ETA:"}
                    value={flightItem.actualTime}
                    showTime={form.actualTimeTime}
                    onChange={(value) => handleChange("actualTime", value)}
                    toggleTime={(value) => handleChange("actualTimeTime", value)}
                    disabled={!canEdit}
                />
                {formType !== 'obc' && flightIndex === 0 && (
                    <>
                        <Spacing size={Spacing.SIZES.SIZE_20} type={Spacing.TYPES.VERTICAL}/>
                        <Col>
                            <TextContainer>{"AWB"}</TextContainer>
                            <FileInput
                                canEdit={canEdit}
                                setFiles={(files) =>
                                    handleChange("files", [...form.files, ...files])
                                }
                                removeFile={(link) =>
                                    handleChange(
                                        "files",
                                        form.files.filter((file) => file.link !== link)
                                    )
                                }
                                files={form.files}
                            />
                        </Col>
                    </>
                )}

                {(isDelayed || isLate) && (
                    <>
                        <Spacing size={Spacing.SIZES.SIZE_20} type={Spacing.TYPES.VERTICAL}/>
                        <AlertInput
                            canEdit={canEdit}
                            form={form}
                            onChange={(value) => handleChange("reasonOfDelay", value)}
                        />
                    </>
                )}
            </Card>
        </div>
    );
};

const TextContainer = styled.span`
   {
    font-weight: 300;
    font-size: 16px;
    margin-right: 10px;
  }
`;
