import React from "react";
import styled from 'styled-components'
import PropTypes from "prop-types";
import {Tag} from "antd";
import {statusTypes} from "../constants";

export const StatusTag = ({status}) => {
    const decideColor = () => {
        switch(status) {
            case 'not-started':
                return 'default'
            case 'on-time':
                return 'processing'
            case 'delayed':
                return 'warning'
            case 'done':
                return 'success'
            default: return 'default'
        }
    }
    return (
        <StyledTag color={decideColor()}>
            Status: {statusTypes.find((item) => item.value === status).label}
        </StyledTag>
    );
};

const StyledTag = styled(Tag)`
    padding: 2px 6px;
    font-size: 14px;
    ${({color}) => color === 'default' && 'color: rgba(0, 0, 0, 0.65)'}
`

StatusTag.propTypes = {
    status: PropTypes.oneOf(['on-time', 'late', 'not-tarted', 'done'])
};
StatusTag.defaultProps = {};
