import React from "react";
import styled from "styled-components";
import {
    FileInput,
    DatePicker,
    AlertInput,
    Spacing
} from "../../index";

export const GoodsDeliveredForm = ({form, onChange, canEdit}) => {
    const isDelayed = form.status === "delayed";
    const isLate = form.status === "late";
    return (
        <div>
            <DatePicker
                text={"Estimated delivery time:"}
                value={form.edt}
                showTime={form.edtTime}
                onChange={(value) => onChange("edt", value)}
                toggleTime={(value) => onChange("edtTime", value)}
                disabled={!canEdit}
            />
            <Spacing size={Spacing.SIZES.SIZE_20} type={Spacing.TYPES.VERTICAL}/>
            <DatePicker
                text="Delivered"
                value={form.delivered}
                showTime={form.deliveredTime}
                onChange={(value) => onChange("delivered", value)}
                toggleTime={(value) => onChange("deliveredTime", value)}
                disabled={!canEdit}
            />
            <Spacing size={Spacing.SIZES.SIZE_20} type={Spacing.TYPES.VERTICAL}/>
            <TextContainer>Download POD</TextContainer>
            <FileInput
                canEdit={canEdit}
                setFiles={(files) => onChange("pod", [...form.pod, ...files])}
                removeFile={(link) => onChange("pod", form.pod.filter((file) => file.link !== link))}
                files={form.pod}
            />
            {(isDelayed || isLate) && (
                <>
                    <Spacing size={Spacing.SIZES.SIZE_20} type={Spacing.TYPES.VERTICAL}/>
                    <AlertInput
                        canEdit={canEdit}
                        value={form.reasonOfDelay}
                        form={form}
                        onChange={(value) => onChange("reasonOfDelay", value)}
                    />
                </>
            )}
            <Spacing size={Spacing.SIZES.SIZE_20} type={Spacing.TYPES.VERTICAL}/>
        </div>
    );
};
const TextContainer = styled.text`
   {
    font-weight: 300;
    font-size: 16px;
    margin-right: 10px;
  }
`;
