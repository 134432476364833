import React from "react";
import styled from "styled-components";
import {Col, Row} from "antd";

import {Checkbox, Select, Spacing} from "../index";
import {statusTypes} from "../../constants";
import {StatusTag} from "../StatusTag";

export const StatusHandler = ({
                                  canEdit,
                                  value,
                                  onChange,
                                  title,
                                  sectionEnabled,
                                  onSectionToggle
                              }) => {
    return (
        <>
            <Row
                justify='space-between'
                align={canEdit ? "top" : "middle"}
                style={{width: '100%'}}
                wrap
            >
                <Col span={8}>
                    <Title>{title}</Title>
                </Col>
                <Col span={8}>
                    <div
                        style={{display: 'flex', alignItems: 'end', justifyContent: 'end'}}
                        onClick={(e) => e.stopPropagation()}
                    >
                        {canEdit && (
                            <>
                                <Checkbox
                                    label="Section Enabled"
                                    value={sectionEnabled}
                                    onChange={onSectionToggle}
                                />
                                <Spacing size={Spacing.SIZES.SIZE_16} type={Spacing.TYPES.HORIZONTAL}/>
                                <Select
                                    text="Status"
                                    onChange={onChange}
                                    options={statusTypes}
                                    value={value}
                                    disabled={!canEdit}
                                    allowClear={false}
                                />
                            </>
                        )}
                        {(!canEdit && <StatusTag status={value}/>)}
                    </div>
                </Col>
            </Row>
            <Spacing size={Spacing.SIZES.SIZE_16}/>
        </>
    );
};

const Title = styled.span`
    font-size: 20px;
`;
