import React, {useEffect, useState} from "react";
import {Col, notification, Row, Table} from 'antd';
import {Icon, Input, Spacing, Tabs} from "../components";
import {getShipmentsByStatus} from "../components/firebase-utils";
import {Link, useNavigate} from "react-router-dom";
import {Flex} from "../flex";


export const Overview = ({loggedIn, setId}) => {
    const [data, setData] = useState([])
    const [filter, setFilter] = useState('')
    const [type, setType] = useState('incomplete')
    const navigate = useNavigate()

    const getShipments = (status) => getShipmentsByStatus(status === 'completed')
        .then((res) => setData(res))

    useEffect(() => {
        getShipments(type)
    }, [])



    const columns = [
        {
            title: '#SDL',
            dataIndex: 'sdlId',
            key: 'sdlId',
            width: 200,
            render: (text) => {
                return (
                    <Flex align={Flex.POSITION.CENTER}>
                        <Link to={'/shipment/' + text} onClick={() => setId(text)}>
                            {text}
                        </Link>
                        <Spacing size={Spacing.SIZES.SIZE_16} type={Spacing.TYPES.HORIZONTAL}/>
                        <div style={{cursor: 'pointer'}} onClick={() => {
                            window.navigator.clipboard.writeText('https://samedaylogistics-tracking.com/shipment/' + text)
                            notification.success({message: 'Url copied'})
                        }}>
                            <Icon name={Icon.ICONS.COPY} size={Icon.SIZES.SMALL}/>
                        </div>
                    </Flex>
                );
            },
        },
        {
            title: 'Service type',
            dataIndex: 'type',
            key: 'type',
            width: 200
        },
        {
            title: 'Note',
            dataIndex: 'note',
            key: 'note',
        },
    ];
    const tabsConfig = [
        {
            label: <>In progress</>,
            key: 'incomplete',
            closable: false,
        },
        {
            label: <>Completed</>,
            key: 'completed',
            closable: false,
        },
    ]
    const filteredData = data.filter((item) => filter ? (item.sdlId.includes(filter) || item.note.includes(filter)) : true)
    useEffect(() => {
        if (!loggedIn) {
            navigate('/')
        }
    }, [])
    return (
        <div style={{background: "rgb(230 240 255 / 30%)", height: '100%'}}>
            <div style={{maxWidth: 1060, margin: '0 auto'}}>
                <Spacing size={Spacing.SIZES.SIZE_20} type={Spacing.TYPES.BOTH}>
                    <h2>Přehled aktivních zásilek</h2>
                    <Spacing size={Spacing.SIZES.SIZE_16}/>
                    <Row>
                        <Col span={12}>
                            <Input
                                text={'Vyhledávání v záznemech'}
                                onChange={(value) => setFilter(value)}
                                value={filter}
                            />
                        </Col>
                    </Row>
                    <Spacing size={Spacing.SIZES.SIZE_16}/>
                    <Tabs
                        config={tabsConfig}
                        onChange={(val) => {
                            setType(val)
                            return getShipments(val)
                        }}
                        value={type}
                        canEdit={true}
                    />
                    <Table
                        columns={columns}
                        dataSource={filteredData}
                        pagination={false}
                        bordered
                    />
                </Spacing>
            </div>
        </div>
    );
};

Overview.propTypes = {};
Overview.defaultProps = {};
