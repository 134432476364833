import React, {useState} from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import {Collapse, Table} from "antd";

export const PackagesTable = ({packages}) => {
    const [isOpen, setOpen] = useState(false)
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity'
        },
        {
            title: 'Weight (kg)',
            dataIndex: 'weight',
            key: 'weight'
        },
        {
            title: 'Packaging',
            dataIndex: 'packaging',
            key: 'packaging'
        },
        {
            title: 'Goods type',
            dataIndex: 'goodsType',
            key: 'goodsType'
        },
        {
            title: 'Dimensions: HxWxD (cm)',
            dataIndex: 'dimensions',
            key: 'dimensions'
        },
        {
            title: 'Stackable',
            dataIndex: 'stackable',
            key: 'stackable',
            render: (stackable) => stackable ? 'Yes' : 'No'
        },
    ]

    return (
        <>
            <Collapse
                style={{width: '100%'}}
                defaultActiveKey={['1']}
                bordered={false}
                activeKey={isOpen ? '1' : null}
                expandIconPosition={'end'}
                onChange={() => setOpen(isOpen ? null : '1')}
            >
                <StyledPanel header='Packages list' key='1'>
                    <Table
                        style={{width: '100%'}}
                        columns={columns}
                        dataSource={packages}
                        pagination={false}
                    />

                </StyledPanel>
            </Collapse>
        </>
    );
};

const StyledPanel = styled(Collapse.Panel)`
    .ant-collapse-header-text {
       font-weight: 400;
       font-size: 20px;
    }
`

PackagesTable.propTypes = {};
PackagesTable.defaultProps = {};
