import ShortUniqueId from "short-unique-id";

const uid = new ShortUniqueId({ length: 6 });

export const serviceTypes = [
  {
    label: "Airfreight",
    value: "airfreight",
  },
  {
    label: "OBC",
    value: "obc",
  },
  {
    label: "DRF",
    value: "drf",
  },
  {
    label: "Charter",
    value: "charter",
  },
];

export const statusTypes = [
  {
    label: "Not started",
    value: "not-started",
  },

  {
    label: "On time",
    value: "on-time",
  },

  {
    label: "Delayed",
    value: "delayed",
  },
  {
    label: "Done",
    value: "done",
  },
];

export const initialPackage = () => ({
  id: uid(),
  packageNumber: "",
  itemCount: "",
  weight: "",
  packaging: "",
  goodsType: "",
  stackable: false,
  dimensions: "",
  sectionEnabled: true,
  quantity: "",
});

export const initialFlight = () => ({
  id: uid(),
  actualTime: null,
  actualTimeTime: true,
  estimatedTime: null,
  estimatedTimeTime: true,
  departureAirport: "",
  arrivalAirport: "",
  flightNumber: "",
  status: "not-started",
  sectionEnabled: true,
  reasonOfDelay: "",
  files: [],
  goodsConfirmedOnboard: false,
});

export const initialWB = () => ({
  id: uid(),
  name: "",
  latestMessage: '',
  packages: [initialPackage()],
  shipmentConfirm: {
    contacted: false,
    capacityBooked: false,
    documents: [],
    itinerary: [],
    passport: [],
    status: "not-started",
    confirmationDate: null,
    confirmationDateTime: true,
    clientReference: "",
    sectionEnabled: true,
    reasonOfDelay: "",
    courierName: "",
    courierPhone: "",
    currierReserved: false,
    klicEnabled: true,
  },
  pickedUp: {
    etaPickUp: null,
    etaPickUpTime: true,
    collected: null,
    collectedTime: true,
    exportCustoms: false,
    goodsTendered: false,
    status: "not-started",
    reasonOfDelay: "",
    sectionEnabled: true,
    plateNumber: "",
    driversName: "",
    // OBC
    actualTime: null,
    actualTimeTime: false,
    vehicleETA: null,
    vehicleETATime: false
  },
  flightDeparture: {
    sectionEnabled: true,
    flights: [initialFlight()],
  },
  goodsRetrieved: {
    airlineNotification: false,
    shipmentArrived: false,
    importCustoms: [],
    importCustomsDone: false,
    enabledImports: true,
    etaUnloading: null,
    etaUnloadingTime: true,
    etaLoading: null,
    etaLoadingTime: true,
    handedToDriver: null,
    handedToDriverTime: true,
    status: "not-started",
    sectionEnabled: true,
    reasonOfDelay: "",
    plateNumber: "",
    driversName: "",
    customsOfficerName: "",
  },
  goodsPickedUp: {
    actualTime: null,
    actualTimeTime: true,
    estimatedTime: null,
    estimatedTimeTime: true,
    status: "not-started",
    sectionEnabled: true,
    reasonOfDelay: "",
  },
  goodsDelivered: {
    status: "not-started",
    edt: null,
    edtTime: false,
    reasonOfDelay: "",
    pod: [],
    sectionEnabled: true,
    documents: [],
    delivered: null,
    deliveredTime: true,
  },
  goodsHanded: {
    goodsHanded: false,
    status: "not-started",
    sectionEnabled: true,
    reasonOfDelay: "",
  },
});

export const initialShipment = () => ({
  sdlId: "",
  type: "airfreight",
  subscribedEmails: [],
  completed: false,
  wayBills: [initialWB()],
  pickUpAddress: "",
  deliveryAddress: ""
});
