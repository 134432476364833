import {Col, Input, Row} from "antd";
import {Link, useNavigate} from "react-router-dom";
import {useState} from "react";
import styled from "styled-components";

import {Flex} from "../../flex";
import {Spacing, Button} from "../../components";

export const Navigation = ({loggedIn, setState, sdlNumber, hideSearch}) => {
    const [search, setSearch] = useState(sdlNumber);
    const navigate = useNavigate()
    const {Search} = Input;

    const handleLogout = () => {
        sessionStorage.clear();
        setState("loggedIn", false);
    };

    const onSearch = () => {
        setState("sdlId", search);
        navigate("/shipment/" + search);
    };

    return (
        <Spacing size={Spacing.SIZES.SIZE_16} type={Spacing.TYPES.HORIZONTAL}>
            <Spacing size={Spacing.SIZES.SIZE_10}>
                <Row gutter={{xs: 16, md: 0}} justify='space-between' width='100%'>
                    <Col>
                        <Link to="/">
                            <Logo/>
                        </Link>
                    </Col>
                    {!hideSearch && (
                        <Col span={0} md={8}>
                            <Search
                                value={search !== "new" ? search : null}
                                className="search"
                                onChange={(e) => setSearch(e.target.value)}
                                placeholder="Search SDL Number"
                                allowClear
                                enterButton="Search"
                                size="large"
                                onSearch={onSearch}
                            />
                        </Col>
                    )}
                    <Col>
                        <NavButtons>
                            {loggedIn && (
                                <Button
                                    type="primary"
                                    className="blue"
                                    text="Overview"
                                    onClick={() => {
                                        navigate("/overview");
                                    }}
                                />
                            )}
                            <Spacing
                                size={Spacing.SIZES.SIZE_10}
                                type={Spacing.TYPES.HORIZONTAL}
                            />
                            {loggedIn && (
                                <Button
                                    type="primary"
                                    className="blue"
                                    text="Create shipment"
                                    onClick={() => {
                                        setState("sdlId", "new");
                                        navigate("/shipment/new");
                                    }}
                                />
                            )}
                            <Spacing
                                size={Spacing.SIZES.SIZE_10}
                                type={Spacing.TYPES.HORIZONTAL}
                            />
                            {loggedIn ? (
                                <Button
                                    text="Log out"
                                    type="secondary"
                                    onClick={() => {
                                        setState("loggedIn", handleLogout());
                                    }}
                                />
                            ) : (
                                <Link to="/login">
                                    <Button text="Log in" type="primary" onClick={() => null}/>
                                </Link>
                            )}
                        </NavButtons>
                    </Col>
                    <Col span={24} md={0}>
                        <Spacing size={Spacing.SIZES.SIZE_8} />
                        <Search
                            value={search !== "new" ? search : null}
                            className="search"
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder="Search SDL Number"
                            allowClear
                            enterButton="Search"
                            size="large"
                            onSearch={onSearch}
                        />
                    </Col>
                </Row>
            </Spacing>
        </Spacing>
    );
};

const Logo = styled.div`
   {
    background: url(/images/logo.png);
    width: 182px;
    height: 30px;
    background-size: cover;
    margin-top: 5px;
    cursor: pointer;
  }
`;
const NavButtons = styled.div`
   {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;
