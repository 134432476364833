import React from "react";
import {Col, Row} from "antd";
import {Flex} from "../../flex";
import styled from "styled-components";
import {Button, Checkbox, Input, Select, Spacing} from "../../components";
import {serviceTypes} from "../../constants";

export const BlueStripe = ({
                               canEdit,
                               sdlId,
                               form,
                               setForm,
                               handleFormSave,
                               isSaving,
                               showConfirm

                           }) => (
    <div
        style={{
            width: "auto",
            background: "#1677FF",
            padding: '20px 0'
        }}
    >
        <div
            style={{
                display: "flex",
                flexDirection: 'column',
                alignItems: "start",
                justifyContent: 'center',
                maxWidth: 1060,
                padding: '0 16px',
                margin: "0 auto",
            }}
        >
            <Row style={{width: '100%'}}>
                <Col span={24} md={{span: 17}}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Row align='bottom'>
                                <Col span={24} md={11}>
                                    <Flex align={Flex.POSITION.CENTER}>
                                        <SdlText>SDL #</SdlText>
                                        {!canEdit && (
                                            <SdlNumber disabled={!canEdit || sdlId !== "new"}>
                                                {form?.sdlId}
                                            </SdlNumber>
                                        )}
                                        {canEdit && (
                                            <Input
                                                value={form?.sdlId}
                                                onChange={(value) => setForm({...form, sdlId: value})}
                                                disabled={!canEdit || sdlId !== "new"}
                                                type='number'
                                            />
                                        )}
                                    </Flex>
                                </Col>

                                <Col span={24} md={{span: 12, offset: 1}}>
                                    {canEdit && (
                                        <>
                                            <Text>Service Type</Text>
                                            <Select
                                                onChange={(value) => setForm({...form, type: value})}
                                                options={serviceTypes}
                                                value={form.type}
                                                disabled={!canEdit}
                                                form={form}
                                            />
                                        </>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            <Row>
                                <Col span={24} md={11}>
                                    <Text>Pick up address</Text>
                                    <Input
                                        value={form?.pickUpAddress}
                                        onChange={(value) => setForm({...form, pickUpAddress: value})}
                                        disabled={!canEdit}
                                    />
                                </Col>
                                <Col span={24} md={{span: 12, offset: 1}}>
                                    <Text>Delivery address</Text>
                                    <Input
                                        value={form?.deliveryAddress}
                                        onChange={(value) => setForm({...form, deliveryAddress: value})}
                                        disabled={!canEdit}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        {canEdit && (
                            <Col span={24}>
                                <Text>Note</Text>
                                <Input
                                    value={form.note}
                                    onChange={(value) => setForm({...form, note: value})}
                                />
                            </Col>
                        )}
                    </Row>
                </Col>
                {canEdit && (
                    <Col span={24} md={{span: 6, offset: 1}}>

                        <Text>
                            <Checkbox
                                onChange={(value) => setForm({...form, completed: value})}
                                value={form.completed}
                                label={'Completed'}
                            />
                        </Text>
                        <Spacing size={Spacing.SIZES.SIZE_8}/>
                        <Row gutter={[10, 10]}>
                            <ButtonContainer>
                                <Button
                                    text={sdlId === "new" ? "Create new shipment" : "Save changes"}
                                    onClick={handleFormSave}
                                    loading={isSaving}
                                />
                            </ButtonContainer>
                            {sdlId !== "new" && (
                                <>
                                    <Spacing size={Spacing.SIZES.SIZE_10}
                                             type={Spacing.TYPES.HORIZONTAL}/>
                                    <RedButtonContainer>
                                        <Button
                                            text="Delete"
                                            onClick={showConfirm}
                                            disabled={isSaving}
                                        />
                                    </RedButtonContainer>
                                </>
                            )}
                        </Row>
                    </Col>
                )}
            </Row>

        </div>
    </div>
);

BlueStripe.propTypes = {};
BlueStripe.defaultProps = {};


const ButtonContainer = styled.div`
  .ant-btn-primary {
    background-color: #28b547;
  }
`;
const RedButtonContainer = styled.div`
  .ant-btn-primary {
    background-color: rgba(255, 0, 0, 0.75);
  }
`;

const SdlText = styled.text`
   {
    color: white;
    font-size: 18px;
    font-weight: 300;
    margin-right: 12px;
    white-space: nowrap;
  }
`;
const SdlNumber = styled.text`
   {
    font-size: 24px;
    color: white;
    font-weight: 500;
  }
`;
const Text = styled.span`
  color: white
`
