import React from "react";
import {Col, Row} from "antd";
import {
    Checkbox,
    DatePicker,
    Input,
    AlertInput,
    Spacing
} from "../../index";
import {Flex} from "../../../flex";

export const PickedUpForm = ({form, onChange, canEdit, formType}) => {
    const isDelayed = form.status === "delayed";
    const isLate = form.status === "late";
    return (
        <div>
            {(formType.type === "airfreight" ||
                formType.type === "drf") && (
                <>
                    <DatePicker
                        text="Estimated pick up time:"
                        value={form.etaPickUp}
                        showTime={form.etaPickUpTime}
                        onChange={(value) => onChange("etaPickUp", value)}
                        toggleTime={(value) => onChange("etaPickUpTime", value)}
                        disabled={!canEdit}
                    />
                    <Spacing size={Spacing.SIZES.SIZE_20} type={Spacing.TYPES.VERTICAL}/>
                    <DatePicker
                        text="Goods collected:"
                        value={form.collected}
                        showTime={form.collectedTime}
                        onChange={(value) => onChange("collected", value)}
                        toggleTime={(value) => onChange("collectedTime", value)}
                        disabled={!canEdit}
                    />
                </>
            )}

            {formType.type === "obc" && (
                <>
                    <DatePicker
                        text="Goods collected:"
                        value={form.actualTime}
                        showTime={form.actualTimeTime}
                        onChange={(value) => onChange("actualTime", value)}
                        toggleTime={(value) => onChange("actualTimeTime", value)}
                        disabled={!canEdit}
                    />
                    <Spacing size={Spacing.SIZES.SIZE_20} type={Spacing.TYPES.VERTICAL}/>
                    <DatePicker
                        text="ETA of the vehicle to the loading place"
                        value={form.vehicleETA}
                        showTime={form.vehicleETATime}
                        onChange={(value) => onChange("vehicleETA", value)}
                        toggleTime={(value) => onChange("vehicleETATime", value)}
                        disabled={!canEdit}
                    />
                </>
            )}
            <Spacing size={Spacing.SIZES.SIZE_20} type={Spacing.TYPES.VERTICAL}/>
            <Row>
                <Col span={24} md={7}>
                    <Input
                        text="Plate number"
                        onChange={(value) => onChange("plateNumber", value)}
                        value={form.plateNumber}
                        disabled={!canEdit}
                    />
                </Col>
                <Spacing size={Spacing.SIZES.SIZE_20} type={Spacing.TYPES.HORIZONTAL}/>
                <Col span={24} md={7}>
                    <Input
                        text="Driver’s name"
                        onChange={(value) => onChange("driversName", value)}
                        value={form.driversName}
                        disabled={!canEdit}
                    />
                </Col>
            </Row>

            {formType.type === "charter" && (
                <>
                    <Spacing size={Spacing.SIZES.SIZE_16} type={Spacing.TYPES.VERTICAL}/>
                    <Checkbox
                        label="Export customs done"
                        value={form.exportCustoms}
                        onChange={(value) => onChange("exportCustoms", value)}
                        disabled={!canEdit}
                    />
                    <Spacing size={Spacing.SIZES.SIZE_20} type={Spacing.TYPES.VERTICAL}/>
                    {(isDelayed || isLate) && (
                        <AlertInput
                            canEdit={canEdit}
                            value={form.reasonOfDelay}
                            form={form}
                            onChange={(value) => onChange("reasonOfDelay", value)}
                        />
                    )}

                    <Spacing size={Spacing.SIZES.SIZE_20} type={Spacing.TYPES.VERTICAL}/>
                </>
            )}
            {(isDelayed || isLate) && (
                <>
                    <Spacing size={Spacing.SIZES.SIZE_20} type={Spacing.TYPES.VERTICAL}/>
                    <AlertInput
                        canEdit={canEdit}
                        value={form.reasonOfDelay}
                        form={form}
                        onChange={(value) => onChange("reasonOfDelay", value)}
                    />
                </>
            )}

            <Spacing size={Spacing.SIZES.SIZE_20} type={Spacing.TYPES.VERTICAL}/>
        </div>
    );
};
