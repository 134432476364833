import {Col, DatePicker as AntPicker, Row} from "antd";
import {TimePicker as AntTimePicker} from "antd";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import styled from "styled-components";

import {Spacing} from "../spacing";
import {Checkbox} from "../checkbox";

export const DatePicker = ({
                               text,
                               value,
                               onChange,
                               disabled,
                               showTime,
                               toggleTime
                           }) => {
    const handleChange = (value) => onChange(dayjs(value).format(), showTime);

    return (
        <div>
            <TextContainer>{text}</TextContainer>
            <Row align='middle' gutter={[8, 8]}>
                <Col span={24} md={8}>
                    <DateContainer>
                        <AntPicker
                            onChange={handleChange}
                            value={value ? dayjs(value) : undefined}
                            disabled={disabled}
                        />
                    </DateContainer>
                </Col>
                {(showTime || !disabled) && (
                    <Col span={24} md={8}>
                        <Spacing size={Spacing.SIZES.SIZE_8} type={Spacing.TYPES.HORIZONTAL}/>
                        <DateContainer>
                            <AntTimePicker
                                format="HH:mm"
                                onSelect={handleChange}
                                value={value ? dayjs(value) : undefined}
                                disabled={disabled}
                            />
                        </DateContainer>
                    </Col>
                )}
                {!disabled && (
                    <Col span={24} md={8}>
                        <Spacing size={Spacing.SIZES.SIZE_20} type={Spacing.TYPES.HORIZONTAL}/>
                        <Checkbox
                            value={showTime}
                            onChange={toggleTime}
                            label='Enable time'
                        />
                    </Col>
                )}
            </Row>
        </div>
    );
};


DatePicker.propTypes = {
    text: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    showTime: PropTypes.bool
};
DatePicker.defaultProps = {
    showTime: true,
    disabled: false,
}

const DateContainer = styled.div`
    width:100%;
  & .ant-picker .ant-picker-input > input[disabled] {
    cursor: default;
    color: black;
  }
  .ant-picker-disabled {
    cursor: default;
    color: white;
    border: none;
    background-color: rgba(230, 240, 255, 0.3);
  }
  .ant-picker {
    width:100%;
    border-radius: 4px;
  }
`;
const TextContainer = styled.div`
   {
    font-weight: 400;
    margin-bottom: 6px;
  }
`;
