import {Col, Row} from "antd";
import {StatusHandler} from './StatusHandler';
import {Flight} from "./Flight";
import {
    ShipmentConfirmation,
    PickedUpForm,
    GoodsDeliveredForm,
    GoodsRetrievedForm,
} from "./steps";
import {Flex} from "../../flex";
import {Steps} from "../step";
import {
    Checkbox,
    Button,
    Spacing, Alert,
} from "../index";
import dayjs from "dayjs";
import {useEffect} from "react";
import {StatusTag} from "../StatusTag";

export const ShipmentSteps = ({
                                  canEdit,
                                  activeWaybill,
                                  handleStepDataChange,
                                  form,
                                  handleAddTransfer,
                                  handleFlightDelete,
                                  handleFlightChange,
                                  emailOrder,
                                  setMessage
                              }) => {
    const ShipmentConfirmStatus = activeWaybill?.shipmentConfirm.status;
    const PickedUpSectionStatus = activeWaybill?.pickedUp.status;
    const GoodsRetrievedSectionStatus = activeWaybill?.goodsRetrieved.status;
    const GoodsDeliveredSectionStatus = activeWaybill?.goodsDelivered.status;

    const showSection = (status) => status !== 'not-started' || canEdit

    const decideFlightStatus = () => {
        const flights = activeWaybill.flightDeparture.flights
        const done = flights.every((flight) => flight.status === 'done')
        const notStarted = flights.every((flight) => flight.status === 'not-started')
        const onTime = flights.some((flight) => flight.status === 'on-time')
        const delayed = flights.some((flight) => flight.status === 'delayed')
        if(delayed) return 'delayed'
        if(onTime) return 'on-time'
        if(notStarted) return 'not-started'
        if(done) return 'done'
    }

    const configSteps = [
        {
            id: "shipmentConfirm",
            data: activeWaybill?.shipmentConfirm,
            step: 1,
            title: (
                <StatusHandler
                    canEdit={canEdit}
                    value={activeWaybill?.shipmentConfirm?.status}
                    onChange={(value) => handleStepDataChange("status", value, "shipmentConfirm")}
                    sectionEnabled={activeWaybill?.shipmentConfirm?.sectionEnabled}
                    onSectionToggle={(value) =>
                        handleStepDataChange("sectionEnabled", value, "shipmentConfirm")
                    }
                    title={"Transport confirmation"}
                />
            ),
            show: showSection(ShipmentConfirmStatus),
            content: (
                <ShipmentConfirmation
                    canEdit={canEdit}
                    onChange={(key, value) =>
                        handleStepDataChange(key, value, "shipmentConfirm")
                    }
                    form={activeWaybill?.shipmentConfirm}
                    formType={form}
                />
            )
        },
        {
            id: "pickedUp",
            data: activeWaybill?.pickedUp,
            step: 2,
            title: (
                <StatusHandler
                    canEdit={canEdit}
                    value={activeWaybill?.pickedUp?.status}
                    onChange={(value) => handleStepDataChange("status", value, "pickedUp")}
                    sectionEnabled={activeWaybill?.pickedUp?.sectionEnabled}
                    onSectionToggle={(value) => handleStepDataChange("sectionEnabled", value, "pickedUp")}
                    title={form.type === "obc" ? "Pick up" : "Picked up"}
                />
            ),
            show: showSection(PickedUpSectionStatus),
            content: (
                <PickedUpForm
                    formType={form}
                    canEdit={canEdit}
                    form={activeWaybill?.pickedUp}
                    onChange={(key, value) =>
                        handleStepDataChange(key, value, "pickedUp")
                    }
                />
            ),
        },
        form.type !== "drf" && {
            id: "flightDeparture",
            data: activeWaybill?.flightDeparture,
            step: 3,
            title: (
                <Flex justify={Flex.POSITION.SPC_BETWEEN} align={Flex.POSITION.END} stretch>
                    <span style={{fontSize: 24, weight: 600}}>Flight schedule</span>
                    {canEdit && (
                        <Checkbox
                            label="Section Enabled"
                            value={activeWaybill?.flightDeparture?.sectionEnabled}
                            onChange={(value) =>
                                handleStepDataChange(
                                    "sectionEnabled",
                                    value,
                                    "flightDeparture"
                                )
                            }
                        />
                    )}
                    {!canEdit && (
                        <StatusTag
                            status={decideFlightStatus()}
                        />
                    )}
                </Flex>
            ),
            show: true,
            content:
                <>
                    {activeWaybill?.flightDeparture.flights.map((flightItem, index) => {
                        return (((flightItem.status !== "not-started") && !canEdit) || canEdit) && (
                            <Flight
                                key={flightItem.id}
                                canEdit={canEdit}
                                flightItem={flightItem}
                                handleFlightDelete={() => handleFlightDelete(flightItem.id)}
                                handleChange={(key, value) =>
                                    handleFlightChange(key, value, flightItem.id)
                                }
                                form={flightItem}
                                formType={form.type}
                                shouldShow={form.type !== 'obc' || (form.type === 'obc' && index === 0)}
                                flightIndex={index}
                                emailOrder={emailOrder}
                            />
                        );
                    })}
                    {canEdit && (
                        <>
                            <Spacing
                                size={Spacing.SIZES.SIZE_20}
                                type={Spacing.TYPES.VERTICAL}
                            />
                            <Flex justify={Flex.POSITION.START}>
                                {canEdit && (
                                    <Button text="Add transfer" onClick={handleAddTransfer}/>
                                )}
                            </Flex>
                            <Spacing
                                size={Spacing.SIZES.SIZE_20}
                                type={Spacing.TYPES.VERTICAL}
                            />
                        </>
                    )}
                </>
        },
        form.type !== "drf" ? {
            id: "goodsRetrieved",
            data: activeWaybill?.goodsRetrieved,
            step: 4,
            title: (
                <StatusHandler
                    canEdit={canEdit}
                    value={activeWaybill?.goodsRetrieved?.status}
                    onChange={(value) => handleStepDataChange("status", value, "goodsRetrieved")}
                    sectionEnabled={activeWaybill?.goodsRetrieved?.sectionEnabled}
                    onSectionToggle={(value) => {
                        handleStepDataChange("sectionEnabled", value, "goodsRetrieved")
                    }}
                    title={"Goods retrieved from the flight"}
                />
            ),
            show: showSection(GoodsRetrievedSectionStatus),
            content: (
                <GoodsRetrievedForm
                    formType={form}
                    canEdit={canEdit}
                    form={activeWaybill?.goodsRetrieved}
                    onChange={(key, value) => {
                        handleStepDataChange(key, value, "goodsRetrieved")
                        if (key === 'shipmentArrived' && !!value) {
                            emailOrder()
                        }
                    }}
                />
            )
        } : {
            data: activeWaybill?.goodsDelivered,
            id: "goodsDelivered",
            step: 4,
            title: (
                <StatusHandler
                    canEdit={canEdit}
                    value={activeWaybill?.goodsDelivered?.status}
                    onChange={(value) => handleStepDataChange("status", value, "goodsDelivered")}
                    sectionEnabled={activeWaybill?.goodsDelivered?.sectionEnabled}
                    onSectionToggle={(value) => {
                        handleStepDataChange("sectionEnabled", value, "goodsDelivered")
                    }}
                    title={"Goods delivered"}
                />
            ),
            show: showSection(GoodsDeliveredSectionStatus),
            content: (
                <GoodsDeliveredForm
                    formType={form}
                    canEdit={canEdit}
                    form={activeWaybill?.goodsDelivered}
                    onChange={(key, value) => handleStepDataChange(key, value, "goodsDelivered")}
                />
            )
        },

        form.type !== "drf" && {
            data: activeWaybill?.goodsDelivered,
            id: "goodsDelivered",
            step: 5,
            title: (
                <StatusHandler
                    canEdit={canEdit}
                    value={activeWaybill?.goodsDelivered?.status}
                    onChange={(value) => handleStepDataChange("status", value, "goodsDelivered")}
                    sectionEnabled={activeWaybill?.goodsDelivered?.sectionEnabled}
                    onSectionToggle={(value) =>
                        handleStepDataChange(
                            "sectionEnabled",
                            value,
                            "goodsDelivered"
                        )
                    }
                    title={"Goods delivered"}
                />
            ),
            content: showSection(GoodsDeliveredSectionStatus) ? (
                <GoodsDeliveredForm
                    formType={form}
                    canEdit={canEdit}
                    form={activeWaybill?.goodsDelivered}
                    onChange={(key, value) =>
                        handleStepDataChange(key, value, "goodsDelivered")
                    }
                />
            ) : null,
        },
    ].filter((item) => !!item);

    const filteredDoneSteps = configSteps.filter((item) => {
        const isEnabledForAdmin = canEdit ? true : item.data.sectionEnabled
        if (item?.id === 'flightDeparture' && isEnabledForAdmin && item.data?.flights.filter((flight) => flight.status === 'done').length === item.data?.flights.length) {
            return true
        }
        return item?.data?.status === "done" && isEnabledForAdmin
    });

    const currentStep = filteredDoneSteps.findIndex(
        (item, index) => filteredDoneSteps.length - 1 === index
    ) + 1;


    function decideMessage(textOnly) {
        const doneFlights = activeWaybill?.flightDeparture?.flights.filter((flight) => flight.status === 'done')
        const desiredFlight = doneFlights.find((_, index) => doneFlights.length - 1 === index)
        const arrived = activeWaybill.goodsRetrieved.shipmentArrived
        const goodsTendered = activeWaybill?.flightDeparture?.flights.some((flight) => flight.goodsTendered)
        const tenderedText = goodsTendered && "Goods tendered to airline's handling agent"
        const estTime = desiredFlight?.estimatedTime && dayjs(desiredFlight.estimatedTime)
            .format(desiredFlight.estimatedTimeTime ? ' YYYY-MM-DD HH:mm' : ' YYYY-MM-DD')

        const lastFlightMessage = desiredFlight && (textOnly ? `Shipment has departed on flight ETD: ${estTime}` : (
                <div>
                    Shipment has departed on flight {' '}
                    <b>{desiredFlight.flightNumber}{' '}</b>
                    ETD:
                    <b>{estTime}</b>
                </div>
            )
        )

        const arrivedMessage = 'Shipment arrived at destination airport.'
        if (activeWaybill.goodsDelivered.status === 'done') {
            return 'Shipment has been delivered.'
        }
        if (activeWaybill.goodsRetrieved.status === 'done') {
            return 'Shipment has been picked up at the destination airport.'
        }
        if (arrived) return arrivedMessage
        if (desiredFlight) return lastFlightMessage
        if (goodsTendered) return tenderedText
        if (activeWaybill.pickedUp.status === 'done') {
            return 'Shipment has been picked up at origin.'
        }
        if (activeWaybill.shipmentConfirm.status === 'done') {
            return 'Transport is well confirmed and documents obtained.'
        }
        return null
    }

    const statusMessage = decideMessage()
    const statusMessageText = decideMessage(true)

    useEffect(() => {
        if (statusMessage) {
            setMessage(statusMessageText)
        }
    }, [statusMessageText])

    return (
        <>
            {statusMessage && (
                <>
                    <Alert title='Latest shipment status' message={decideMessage()}/>
                    <Spacing size={Spacing.SIZES.SIZE_16}/>
                </>
            )}
            <Steps
                config={configSteps}
                current={currentStep}
                form={form}
                canEdit={canEdit}
            />
        </>
    );
};
