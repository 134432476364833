import {Col, Row} from "antd";
import {
    Checkbox,
    AlertInput,
    Input,
    DatePicker,
    FileInput,
    Spacing
} from "../../index";
import styled from "styled-components";

export const GoodsRetrievedForm = ({form, onChange, canEdit, formType}) => {
    const isDelayed = form.status === "delayed";
    const isLate = form.status === "late";

    return (
        <div>
            {formType.type !== "drf" && (
                <Checkbox
                    label="Shipment arrived at destination airport"
                    value={form.shipmentArrived}
                    onChange={(value) => onChange("shipmentArrived", value)}
                    disabled={!canEdit}
                />
            )}
            {formType.type === "airfreight" && (
                <Checkbox
                    label="Arrival notification from the airline received"
                    value={form.airlineNotification}
                    onChange={(value) => onChange("airlineNotification", value)}
                    disabled={!canEdit}
                />
            )}
            <Checkbox
                label="Import customs done"
                value={form.importCustomsDone}
                onChange={(value) => onChange("importCustomsDone", value)}
                disabled={!canEdit}
            />
            {formType.type === "airfreight" && (
                <div>
                    <Checkbox
                        label="Shipment picked up"
                        value={form.pickedUp}
                        onChange={(value) => onChange("pickedUp", value)}
                        disabled={!canEdit}
                    />
                    <Spacing size={Spacing.SIZES.SIZE_20} type={Spacing.TYPES.VERTICAL}/>
                    <DatePicker
                        text="ETA to loading place"
                        value={form.etaLoading}
                        showTime={form.etaLoadingTime}
                        onChange={(value) => onChange("etaLoading", value)}
                        toggleTime={(value) => onChange("etaLoadingTime", value)}
                        disabled={!canEdit}
                    />
                </div>
            )}

            {formType.type === "obc" && (
                <div>
                    <Checkbox
                        label="Shipment handed over to the driver"
                        value={form.pickedUp}
                        onChange={(value) => onChange("pickedUp", value)}
                        disabled={!canEdit}
                    />
                    <Spacing size={Spacing.SIZES.SIZE_20} type={Spacing.TYPES.VERTICAL}/>
                    <DatePicker
                        text="Handed over the driver"
                        value={form.handedToDriver}
                        showTime={form.handedToDriverTime}
                        onChange={(value) => onChange("handedToDriver", value)}
                        toggleTime={(value) => onChange("handedToDriverTime", value)}
                        disabled={!canEdit}
                    />
                </div>
            )}

            <Spacing size={Spacing.SIZES.SIZE_20} type={Spacing.TYPES.VERTICAL}/>
            <DatePicker
                text="ETA to unloading place"
                value={form.etaUnloading}
                showTime={form.etaUnloadingTime}
                onChange={(value) => onChange("etaUnloading", value)}
                toggleTime={(value) => onChange("etaUnloadingTime", value)}
                disabled={!canEdit}
            />
            <Spacing size={Spacing.SIZES.SIZE_20} type={Spacing.TYPES.VERTICAL}/>

            <Row>
                <Col span={24} md={8}>
                    <Input
                        text="Plate number"
                        onChange={(value) => onChange("plateNumber", value)}
                        value={form.plateNumber}
                        disabled={!canEdit}
                    />
                </Col>

                <Spacing size={Spacing.SIZES.SIZE_12} type={Spacing.TYPES.HORIZONTAL}/>

                <Col span={24} md={8}>
                    <Input
                        text="Driver’s name"
                        onChange={(value) => onChange("driversName", value)}
                        value={form.driversName}
                        disabled={!canEdit}
                    />
                </Col>
                <Spacing size={Spacing.SIZES.SIZE_20} type={Spacing.TYPES.HORIZONTAL}/>
                {formType === 'obc' && (
                    <Col span={24} md={8}>
                        <Input
                            text="Customs officer name"
                            onChange={(value) => onChange("customsOfficerName", value)}
                            value={form.customsOfficerName}
                            disabled={!canEdit}
                        />
                    </Col>
                )}
            </Row>
            {form.enabledImports === true && (
                <div>
                    <Spacing size={Spacing.SIZES.SIZE_20}/>
                    <TextContainer>Customs documents</TextContainer>
                    <FileInput
                        canEdit={canEdit}
                        setFiles={(files) => onChange("importCustoms", [...form.importCustoms, ...files])}
                        removeFile={(link) =>
                            onChange(
                                "importCustoms",
                                form.importCustoms.filter((file) => file.link !== link)
                            )
                        }
                        files={form.importCustoms}
                    />
                </div>
            )}

            {(isDelayed || isLate) && (
                <>
                    <Spacing size={Spacing.SIZES.SIZE_20} type={Spacing.TYPES.VERTICAL}/>
                    <AlertInput
                        canEdit={canEdit}
                        value={form.reasonOfDelay}
                        form={form}
                        onChange={(value) => onChange("reasonOfDelay", value)}
                    />
                </>
            )}

            <Spacing size={Spacing.SIZES.SIZE_20} type={Spacing.TYPES.VERTICAL}/>
        </div>
    );
};

const TextContainer = styled.span`
   {
    font-weight: 300;
    font-size: 16px;
    margin-right: 10px;
  }
`;
