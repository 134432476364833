import React, {useState, useEffect} from "react";
import {doc, getDoc} from "@firebase/firestore";
import styled from "styled-components";
import {ExclamationCircleTwoTone} from "@ant-design/icons";
import {Modal, notification, Row, Col, Card, Spin} from "antd";
import {useNavigate} from "react-router-dom";

import {
    Input,
    Package,
    Button,
    Tabs,
    Spacing,
    ShipmentSteps,
    EmailsCard
} from "../../components";
import {Flex} from "../../flex";
import {
    initialFlight,
    initialPackage,
    initialWB,
    initialShipment,
} from "../../constants";
import {
    addNewEmailOrder,
    addShipment,
    db,
    deleteShipment, exists,
    updateShipment,
} from "../../components/firebase-utils";
import {BlueStripe} from "./BlueStripe";
import {PackagesTable} from "./PackagesTable";

export const Detail = ({sdlId, loggedIn, setId}) => {
    const [state, setState] = useState({
        activeTab: null,
        notFound: false,
        newEmailOrders: [],
        loading: [],
        errors: [{notFound: false}],
    });
    const [form, setForm] = useState(null);
    const navigate = useNavigate()
    const canEdit = loggedIn;
    const {confirm} = Modal;

    const activeWaybill = form &&
        form.wayBills.find((wayBill) => wayBill.id === state.activeTab);

    const handleStepDataChange = (key, value, stepKey) => {
        const stepCopy = {...activeWaybill[stepKey]};
        stepCopy[key] = value;
        activeWaybillChange(stepKey, stepCopy);
        if (key === 'status' && (value === 'done' || value === 'delayed')) {
            handleNewEmailOrder()
        }
    };

    const handleAddTransfer = () => {
        let copyFlights = [
            ...activeWaybill.flightDeparture.flights,
            initialFlight(),
        ];
        activeWaybillChange("flightDeparture", {flights: copyFlights});
    };
    const handleAddShipment = () => {
        const shipment = initialShipment();
        setForm(shipment);
        setState({...state, activeTab: shipment.wayBills[0].id});
    };

    const loadData = async () => {
        setState({...state, notFound: false, loading: ['data']});
        const docRef = doc(db, "shipments", sdlId);
        const docSnap = await getDoc(docRef);
        const docData = docSnap.data();

        if (!docSnap.exists()) {
            setState((prev) => ({
                ...prev,
                notFound: true,
                loading: []
            }));
        }

        if (docSnap.exists()) {
            setState((prevState) => ({
                ...prevState,
                activeTab: docData.wayBills[0].id,
                loading: []
            }));
            setForm(docData);
        }
    };

    useEffect(() => {
        if (sdlId === "new") {
            handleAddShipment();
        } else if (sdlId === null) {
            setForm(null);
            setId(null);
        } else {
            loadData();
        }
    }, [sdlId]);

    if (state.notFound) return navigate("/notFound");

    const handleWaybillAdd = () => {
        const newWB = initialWB()
        let wayBillCopy = [...form.wayBills, newWB];
        setForm({...form, wayBills: wayBillCopy});
        setState({...state, activeTab: newWB.id})
    };

    const handleAddPackage = () => {
        let copyPackages = [...activeWaybill.packages, initialPackage()];
        activeWaybillChange("packages", copyPackages);
    };

    const handleChangeTab = (value) => setState({...state, activeTab: value});

    const handlePackageChange = (packageKey, packageValue, id) => {
        let newPackages = [...activeWaybill?.packages];
        const packageIndex = newPackages.findIndex(
            (packageItem) => packageItem.id === id
        );

        newPackages[packageIndex] = {
            ...newPackages[packageIndex],
            [packageKey]: packageValue,
        };
        activeWaybillChange("packages", newPackages);
    };
    const handleFlightChange = (flightKey, flightValue, id) => {
        let newFlights = [...activeWaybill?.flightDeparture.flights];
        const flightIndex = newFlights.findIndex(
            (flightItem) => flightItem.id === id
        );

        newFlights[flightIndex] = {
            ...newFlights[flightIndex],
            [flightKey]: flightValue,
        };
        activeWaybillChange("flightDeparture", {
            ...activeWaybill.flightDeparture,
            flights: newFlights,
        });
    };

    const handleWaybillDelete = (id) => {
        const newWaybills = form.wayBills.filter((item) => item.id !== id);
        setForm({...form, wayBills: newWaybills});

        if (state.activeTab === id) {
            const activeTabIndex = form.wayBills.findIndex((item) => item.id === id);
            const newActiveWaybill = newWaybills[activeTabIndex]
                ? newWaybills[activeTabIndex]
                : newWaybills[activeTabIndex - 1];
            setState({...state, activeTab: newActiveWaybill?.id});
        }
    };
    const handlePackageDelete = (id) => {
        const newPackages = activeWaybill.packages.filter(
            (packageItem) => packageItem.id !== id
        );
        activeWaybillChange("packages", newPackages);
    };

    const handleFlightDelete = (id) => {
        const newFlights = activeWaybill.flightDeparture.flights.filter(
            (flightItem) => flightItem.id !== id
        );
        activeWaybillChange("flightDeparture", {flights: newFlights});
    };

    const activeWaybillChange = (waybillKey, waybillValue) => {
        let wayBillCopy = [...form.wayBills];
        const waybillIndex = wayBillCopy.findIndex(
            (wayBill) => wayBill.id === state.activeTab
        );
        wayBillCopy[waybillIndex] = {
            ...wayBillCopy[waybillIndex],
            [waybillKey]: waybillValue,
        };
        setForm((prev) => ({...prev, wayBills: wayBillCopy}));
    };

    const onEdit = (id, action) => {
        if (action === "add") {
            handleWaybillAdd();
        } else {
            handleWaybillDelete(id);
        }
    };

    const handleAddEmail = (email) => {
        const newEmails = [...form.subscribedEmails, email];
        setForm({...form, subscribedEmails: newEmails});
    };
    const handleDeleteEmail = (index) => {
        const deletedEmails = form.subscribedEmails.splice(index, 1);
        setForm({...form, deletedEmails});
    };

    const handleFormSave = async () => {
        setState({...state, loading: ['saving']})
        const sendEmails = () => {
            if (form.subscribedEmails.length && state.newEmailOrders.length) {
                const wayBills = state.newEmailOrders
                    .map((wbId) => {
                        const desired = form.wayBills.find((wb) => wb.id === wbId)
                        return ({
                            name: desired?.name || wbId,
                            message: desired.latestMessage || ''
                        })
                    })
                    .filter((item) => !!item)
                console.log(wayBills)
                addNewEmailOrder(form.sdlId, wayBills, form.subscribedEmails)
            }
            setState((prev) => ({...prev, newEmailOrders: []}))
        }
        if (!form.sdlId) {
            setState((prev) => ({...prev, loading: []}))
            return notification.error({
                message: "Pro uložení zadejte SDL číslo",
            });
        }
        if (sdlId === "new") {
            const res = await exists(form.sdlId)
            if (res) {
                setState((prev) => ({...prev, loading: []}))
                return notification.error({
                    message: "Tohle SDL# již existuje, zadejte prosím jiné",
                });
            }
            return addShipment(form).then(() => {
                notification.success({
                    message: "Vytvoření proběhlo v pořádku",
                });
                navigate("/shipment/" + form.sdlId);
                setState((prev) => ({...prev, loading: []}))
                setId(form.sdlId);
                sendEmails()
            });
        }
        console.log(form)
        updateShipment(form).then(() => {
            notification.success({
                message: "Uložení proběhlo v pořádku",
            });
            sendEmails()
            setState((prev) => ({...prev, loading: []}))
        });

    }

    function handleNewEmailOrder() {
        const newEmails = new Set([...state.newEmailOrders, activeWaybill.id])
        setState({...state, newEmailOrders: Array.from(newEmails)})
    }

    const showConfirm = () => {
        confirm({
            title: "Do you want to delete this shipment?",
            icon: <ExclamationCircleTwoTone twoToneColor="red"/>,
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk() {
                deleteShipment(sdlId).then(() => {
                    notification.success({
                        message: "Deleting went successfully",
                    });
                    navigate("/");
                    setForm(null);
                    setId(null);
                });
            },
        });
    };
    const isSaving = state.loading.includes('saving')
    if (state.loading.includes('data') || !form) {
        return (
            <Flex flex={1} justify={Flex.POSITION.CENTER}>
                <Spin
                    tip="Loading..."
                    size='large'
                    style={{paddingTop: '250px'}}
                />
            </Flex>
        )
    }
    return (
        <div
            style={{
                background: "rgb(230 240 255 / 30%)",
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            <BlueStripe
                canEdit={canEdit}
                sdlId={sdlId}
                form={form}
                setForm={setForm}
                handleFormSave={handleFormSave}
                isSaving={isSaving}
                showConfirm={showConfirm}
            />
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: 1060,
                    margin: "0 auto",
                    paddingBottom: '16px',
                    overflow: 'hidden',
                    width: '100%',
                }}
            >
                <Row align='middle' justify='space-between'>
                    {canEdit && (
                        <Col span={24} sm={0}>
                            <Spacing size={Spacing.SIZES.SIZE_8} />
                            <Button
                                text='Emails'
                                type={Button.TYPES.secondary}
                                onClick={() => handleChangeTab('emails')}
                            />
                        </Col>
                    )}
                    <Col span={24} sm={18}>
                        <Tabs
                            onEdit={onEdit}
                            form={form}
                            handleWaybillAdd={handleWaybillAdd}
                            handleWaybillDelete={handleWaybillDelete}
                            value={state.activeTab}
                            canEdit={!canEdit}
                            onChange={handleChangeTab}
                            config={form.wayBills.map((item) => ({
                                key: item.id,
                                label: item.name || item.id,
                                closable: !!canEdit && form.wayBills.length !== 1,
                            }))}
                        />
                    </Col>
                    {canEdit && (
                        <Col
                            span={0}
                            sm={4}
                        >
                            <Flex justify={Flex.POSITION.END}>
                                <Button
                                    text='Emails'
                                    type={Button.TYPES.secondary}
                                    onClick={() => handleChangeTab('emails')}
                                />
                            </Flex>
                        </Col>
                    )}
                </Row>
                {state.activeTab !== 'emails' && (
                    <Card style={{overflow: "auto"}}>
                        <Row>
                            <Col span={24} md={12}>
                                {canEdit && (
                                    <Input
                                        text="Name"
                                        value={activeWaybill?.name}
                                        onChange={(value) => activeWaybillChange("name", value)}
                                    />
                                )}
                            </Col>
                        </Row>
                        <ShipmentText>Shipment</ShipmentText>
                        <Row>
                            {canEdit && activeWaybill?.packages.map((packageItem) => (
                                <Col
                                    span={24}
                                    md={8}
                                    key={packageItem.id}
                                    style={{paddingRight: 16, marginTop: 10}}
                                >
                                    <Package
                                        form={form}
                                        canEdit={canEdit}
                                        disabled={!canEdit}
                                        handlePackageDelete={() =>
                                            handlePackageDelete(packageItem.id)
                                        }
                                        packageItem={packageItem}
                                        handleChange={(key, value) =>
                                            handlePackageChange(key, value, packageItem.id)
                                        }
                                    />
                                </Col>
                            ))}
                            {!canEdit && (
                                <PackagesTable packages={activeWaybill?.packages} />
                            )}
                            <Col span={24} md={8}>
                                {canEdit && (
                                    <Card
                                        style={{
                                            height: "98%",
                                            marginTop: 10,
                                            display: 'flex',
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Button text="Add package" onClick={handleAddPackage}/>
                                    </Card>
                                )}
                            </Col>
                        </Row>
                        <Spacing size={Spacing.SIZES.SIZE_20} type={Spacing.TYPES.VERTICAL}/>
                        <ShipmentSteps
                            canEdit={canEdit}
                            activeWaybill={activeWaybill}
                            handleStepDataChange={handleStepDataChange}
                            form={form}
                            emailOrder={handleNewEmailOrder}
                            handleAddTransfer={handleAddTransfer}
                            handleFlightDelete={handleFlightDelete}
                            handleFlightChange={handleFlightChange}
                            setMessage={(message) => activeWaybillChange('latestMessage', message)}
                        />
                    </Card>
                )}
                {state.activeTab === 'emails' && (
                    <EmailsCard
                        form={form}
                        handleAdd={handleAddEmail}
                        handleDelete={handleDeleteEmail}
                        canEdit={canEdit}
                    />
                )}
            </div>
        </div>
    );
};
const ShipmentText = styled.h1`
   {
    font-weight: 600;
    font-size: 24px;
  }
`;

